import Editor from "./Editor";

let $ = jQuery;

class Site {
    public static onHomeEditPage(): void {
        $(() => {
            const $form = $('form');

            $('table tr:odd').addClass('row-2');

            (<any> $('input[name="nip"]', $form)).mask('999-999-99-99');
            (<any> $('input[name="customer_nip"]', $form)).mask('999-999-99-99');
            (<any> $('input[name="regon"]', $form)).mask('999?99999999999');

            const $customer = $('.podmiot-info__customer', $form);
            const $table = $('.podmiot-info__customer-fields', $customer);
            const $icustomer = $('input[name="customer_same"]', $form);

            if ($icustomer.is(':checked')) {
                $table.hide();
            } else {
                $table.show();
            }

            $icustomer.on('change', (e) => {
                if ($icustomer.is(':checked')) {
                    $table.slideUp();
                } else {
                    $table.slideDown();
                }
            });
        });
    }

    public static onPage(): void {
        $(() => {
            const $attachments = $('.pageattachments__item');

            $attachments.each((ix, el) => {
                const $el = $(el);

                const $button = $('.pageattachments__button', $el);
                const $more = $('.pageattachments__more', $el);

                $button.on('click', (e) => {
                    e.preventDefault();

                    $el.toggleClass('pageattachments__item--opened');
                    $more.slideToggle();
                });
            });

            const $archive = $('.page-archive');
            const $expander = $('.page-archive__expander', $archive);
            const $body = $('.body', $archive);

            $expander.on('click', () => {
                const vis = !$body.is(':visible');

                if (vis) {
                    $body.slideDown();
                } else {
                    $body.slideUp();
                }

                $expander.text(vis ? 'zwiń' : 'rozwiń');
            });

            $body.hide();

            const $content = $('#contents-body .paperbox');

            $('table', $content).each((ix, el) => {
                const $table = $(el);

                $table.wrap('<div class="cmstext-table">');
            });
        });
    }

    public static onPageEditor(data: any): void {
        $(() => {
            let editor = new Editor();
            editor.loadJson(data.files);

            (<any> window).tinymce.init({
                selector: '.pageeditor__input',

                language_url: '/theme/js/libs/tinymce/pl.js',

                plugins: 'advlist lists pagebreak link charmap code contextmenu fullscreen hr image insertdatetime media nonbreaking paste searchreplace table textcolor visualblocks visualchars',

                toolbar: [
                    'undo redo | styleselect fontsizeselect | image link | visualblocks',
                    'bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
                ],

                image_advtab: true,

                convert_urls: false,
            });
        });
    }

    public static onPageRegister($form: JQuery): void {
        const $hints = $('.registerform__hints', $form);
        const $hintstext = $('.registerform__hints-text', $form);
        const $submit = $('input[type="submit"]', $form);

        const $inputs = $('.registerform__input', $form);

        const ival = (type: string, value: string) => {
            if (value == null) {
                return false;
            }

            switch(type) {
                case 'login':
                case 'alias':
                    const preg = /^[A-Za-z]([A-Za-z0-9_]{2,29})$/;
                    return preg.test(value);

                    break;

                case 'password':
                    return value.length >= 6;

                case 'email':
                    const reg_email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                    return reg_email.test(value);
            }
        };

        const validate = () => {
            let valid = true;

            $inputs.each((ix, el) => {
                const $el = $(el);
                const $input = $(':input', $el);
                const $correct = $('.correct', $el);
                const $error = $('.error', $el);

                const val = $input.val();
                const isvalid = ival($input.prop('name'), val);

                if (!isvalid) {
                    valid = false;
                }

                $error.empty();

                console.log(val);

                if (val && val.length > 0) {
                    const $img= $('<img>');
                    $img.prop('src', '/theme/images/' + (isvalid ? 'correct.png' : 'incorrect.png'));
                    $img.prop('alt', isvalid ? 'Prawidłowe' : 'Nieprawidłowe');
                    $correct.empty().append($img);
                }
            });

            $submit.prop('disabled', !valid);
        };

        $(':input', $form).on('keyup', (e) => {
            validate();
        });

        $inputs.each((ix, el) => {
            const $el = $(el);
            const $input = $(':input', $el);
            const hint = $el.data('hint');

            $input.on('blur', (e) => {
                $hintstext.html('');
                $hints.removeClass('registerform__hints--opened');
            });

            $input.on('focus', (e) => {
                const ipos = $input.position();

                $hints.addClass('registerform__hints--opened');
                $hints.css({
                    top: ipos.top
                });
                $hintstext.html(hint);
            });
        });

        $form.submit(function() {
            $.post($form.attr('action'), $form.serializeArray(), function(data) {
                $form.replaceWith(data);
            });
            return false;
        });

        validate();
    }

    public static selectSearchBox(value: any): void {
        const $form = $('.searchbox form');
        $form.data('val', value);
        // $form.submit();
    }

    private static initSearchBox(): void {
        const $sb = $('.searchbox');
        const $form = $('form', $sb);
        // const $input = $('input', $form);

        if (!$sb.hasClass('searchbox--entity')) {
            $form.on('submit', (e) => {
                e.preventDefault();

                const val = $form.data('val');

                if (val) {
                    window.location.href = '/' + val.id + '/';
                }
            });
        }
    }

    private static initWcag(): void {
        const $html = $('html');

        $('.switch-highcontrast').on('click', function (e) {
            e.preventDefault();

            if ($html.hasClass('highcontrast')) {
                $html.removeClass('highcontrast');
                window.localStorage.setItem('highcontrast', '0');
            } else {
                $html.addClass('highcontrast');
                window.localStorage.setItem('highcontrast', '1');
            }
        });

        const $zooms = $('.switch-zoom button');

        const selectZoom = (ix: number) => {
            $zooms.removeClass('pagezoom--selected');
            $zooms.eq(ix).addClass('pagezoom--selected');

            $html.removeClass('pagezoom-1 pagezoom-2 pagezoom-3');
            $html.addClass('pagezoom-' + (ix + 1));
        };

        $zooms.on('click', function (e) {
            e.preventDefault();

            const ix = $(this).index();

            selectZoom(ix);

            window.localStorage.setItem('pagezoom', ix.toString());
        });

        const pagezoom = parseInt(window.localStorage.getItem('pagezoom'));

        if (window.localStorage.getItem('highcontrast') == '1') {
            $html.addClass('highcontrast');
        }

        selectZoom(pagezoom);
    }

    private static initHtml(): void {
        const $header = $('.header');

        const $login = $('.loginbox', $header);
        const $loginbtn = $('.loginbox__button', $login);

        $loginbtn.on('click', (e) => {
            e.preventDefault();

            $login.toggleClass('loginbox--opened');
        });

        $('.wcagmenu__search').on('click', (e) => {
            e.preventDefault();

            $('.searchbox input').focus();
        });

        this.initSearchBox();
        this.initWcag();
    }

    public static init(): void {
        (<any> window).Site = Site;

        $(() => {
            this.initHtml();
        });
    }
}

Site.init();
